@import "styles/variables.scss";

button {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: $rem-font-size-small-2;
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 600;
  padding: 5px 10px 3px 10px;
  border-radius: 20px;
  transition: all 0.25s ease;
  line-height: $rem-font-size-medium-1;
  font-size: $rem-font-size-small-3;

  &.capitalize {
    text-transform: capitalize;
  }

  &:focus-visible {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }

  &:hover:enabled {
    cursor: pointer;
    color: white;
    @include box-shadow(0.15);
  }

  &.green-btn {
    color: $green;
    border: 1px solid $green;

    &:hover {
      color: white;
      background-color: $green;
    }
  }

  &.red-btn {
    color: $error;
    border: 1px solid $error;

    &:hover {
      color: white;
      background-color: $error;
    }
  }

  &.primary-btn {
    color: $darkblue;
    border: 1px solid $darkblue;

    &.selected {
      color: white;
      background-color: $darkblue;
    }

    &:hover {
      color: white;
      background-color: $darkblue;
    }

    &:disabled {
      cursor: default;
      opacity: 0.2;
      color: inherit;
      background: inherit;
      box-shadow: none;
    }

    &.invert-primary {
      color: white;
      background-color: $blue;

      &:hover {
        color: $blue;
        background-color: white;
        border: 1px solid $blue;
      }
    }
  }

  &.cancel-btn {
    border: none;
    color: $dark-grey;

    &:hover {
      color: $superdark-grey;
      box-shadow: none;
    }

    &:hover {
      color: $ultradark-grey;
    }
  }

  &.disabled {
    color: $medium-grey;
    border: 2px solid $medium-grey;
  }

  &.text-btn {
    padding: 0;
    font-size: 11px;
    border: none;
    text-decoration: none;
    color: $darkblue;

    &:hover {
      box-shadow: none;
      color: darken($darkblue, 5%);
    }

    &:active {
      color: darken($darkblue, 10%);
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }

    &.green {
      color: $green;

      &:hover {
        box-shadow: none;
        color: darken($green, 5%);
      }

      &:active {
        color: darken($green, 10%);
      }
    }

    &.cancel-btn {
      color: $dark-grey;

      &:hover {
        color: $superdark-grey;
      }

      &:active {
        color: $ultradark-grey;
      }
    }

    &.support-btn {
      position: relative;
      margin-left: 20px;

      .upgrade-icon {
        fill: $blue;
        left: -12px;
      }

      &:hover {
        .upgrade-icon {
          fill: darken($blue, 10%);
        }
      }
    }

    &.delete-btn {
      color: $error;

      &:hover {
        box-shadow: none;
        color: darken($error, 5%);
        background: none;
      }
    }
  }

  &.media-add-btn {
    padding: 7px 0 4px 0;

    i {
      margin-top: -3px;
    }

    &:hover:enabled {
      color: $blue;
    }

    &:active:enabled {
      color: darken($blue, 10%);
    }
  }

  &.share-btn {
    @include mobile {
      i {
        font-size: $rem-font-size-large-1;
      }
    }

    @include tablet {
      i {
        font-size: $rem-font-size-large-1;
      }
    }

    &:hover {
      box-shadow: none;
    }
  }

  &.pano-ctrl-btn {
    padding: 0;
    z-index: 4;
    border: none;
    flex: 1;
    padding: 0;
    height: 100%;
    margin: 0;
    width: 36px;
    height: 36px;
    @include fade-in;
    border-radius: 50%;
    margin-bottom: 17px;

    @include desktop {
      @include box-shadow(0.25);
    }

    #minimap-wrapper & {
      display: none;
    }

    @include desktop {
      border-radius: 50%;
      padding: 5px 6px;
      flex: 0;
      height: 32px;
      width: 32px;
      margin-right: 10px;

      &:hover {
        box-shadow: none;
      }
    }

    &.full-screen {
      i {
        font-size: $rem-font-size-large-3;

        @include desktop {
          font-size: $rem-font-size-large-1;
        }
      }
    }

    &.full-screen,
    &.rotate {
      i {
        margin: 1px 0 0 0;
      }
    }

    &.rotate {
      @include mobile {
        i {
          font-size: $rem-font-size-large-3;
        }
      }

      @include tablet {
        i {
          font-size: $rem-font-size-large-3;
        }
      }

      i {
        font-size: $rem-font-size-large-1;
        margin: 1px 0 0 0;
      }
    }

    &.vr {
      position: relative;

      @include desktop {
        padding: 16px;
      }

      svg.vr-btn {
        position: absolute;
        left: 50%;
        bottom: 20%;
        transform: translateX(-50%);
        fill: $green;
        transition: all 0.25s ease;
        width: 20px;
        height: 20px;

        @include desktop {
          width: 16px;
          height: 16px;
          top: 9px;
        }
      }
    }

    &.share {
      @include mobile {
        i {
          font-size: $rem-font-size-large-1;
        }
      }

      @include tablet {
        i {
          font-size: $rem-font-size-large-1;
        }
      }

      @include desktop {
        padding: 9px;
      }
    }

    &.stop-gallery {
      @include mobile {
        i {
          font-size: $rem-font-size-large-1;
        }
      }

      @include tablet {
        i {
          font-size: $rem-font-size-large-1;
        }
      }

      @include desktop {
        padding: 9px;
      }
    }

    &.show-stops {
      padding: 9px;
      @include mobile {
        i {
          font-size: $rem-font-size-large-1;
        }
      }

      @include tablet {
        i {
          font-size: $rem-font-size-large-1;
        }
      }
    }
  }

  &.media-ctrl-button {
    margin-right: 0;
  }

  &.plan-btn {
    padding: 5px 10px;
  }

  &.display-form-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 60vh;
    border: none;
    height: 46px;
    width: 46px;
    padding: 5px;
    border-radius: 0 10px 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: $rem-font-size-large-2;
    }

    @include mobile-landscape {
      top: 40vh;
    }
  }

  &.tour-forms__close-button {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    position: absolute;
    right: 0;

    .tour-forms__close-button-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.material-icons {
  font-size: $rem-font-size-medium-1;
  vertical-align: -2px;

  &.prev {
    transform: rotate(180deg);
  }
}

.create-new-tour {
  position: absolute;
  top: 27px;
  right: 20px;
  color: $blue;

  &:hover {
    color: darken($blue, 10%);
  }
}

.create-new-tour.text-btn.disabled {
  color: $light-grey;
  cursor: not-allowed;
}

.styles-btn {
  margin-right: 15px;
}

.delete-btn {
  color: $error;
  border-color: $error;

  &:hover {
    color: white;
    background: $error;
  }
}

.btn-update {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  height: 26px;
  width: 90px;
  color: $success;
  border: 1px solid $success;
  outline: none;

  i {
    margin-right: 2px;
    @include fade-in;
  }

  > div {
    margin-right: 4px;
    vertical-align: -1px;
  }

  &:hover {
    background-color: $success;
    color: white;
  }

  &:active {
    background-color: darken($success, 10%);
  }

  &:disabled {
    opacity: 0.75;
    pointer-events: none;
  }
}

.additional-media-update {
  margin-top: 15px;
}

.send-reset-btn {
  padding: 6px 20px;
  margin: 0 5%;

  @include mobile-landscape {
    margin: 0 10%;
  }

  @include tablet {
    margin: 0 20%;
  }

  @include desktop {
    margin: 0 20%;
  }

  &:disabled {
    color: $medium-grey;
    border-color: $medium-grey;
    pointer-events: none;
  }
}

a {
  &.back-btn {
    margin-bottom: 0px;
    color: $darkblue;
    text-transform: uppercase;
    font-size: $rem-font-size-small-4;
    font-weight: 700;

    &:hover {
      color: darken($blue, 5%);
    }

    &:active {
      color: darken($blue, 10%);
    }
  }
}

.starter-upgrade-btn {
  position: relative;
  margin-right: 15px;

  &:hover {
    .upgrade-icon {
      fill: darken($blue, 5%);
    }
  }

  &:active {
    .upgrade-icon {
      fill: darken($blue, 10%);
    }
  }

  .upgrade-icon {
    left: -10px;
    fill: $blue;
  }
}

.upgrade-icon {
  position: relative;
  top: 2px;
  width: 14px;
  transition: all 0.25s ease;
}

.mapboxgl-ctrl > button:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.mapboxgl-ctrl-layer {
  line-height: 42px;
}

.form-icon {
  width: 35px;
}
