$darkblue: #214C6C;
$blue: #62789d;
$activeBlue: #44789d;
$linked-in-blue: #0077b5;

$ultradark-grey: #1a1a1a;
$superdark-grey: #4a4a4a;
$dark-grey: #999999;
$medium-grey: #cccccc;
$light-grey: #e8e8e8;
$superlight-grey: #f1f1f1;
$ultralight-grey: #f8f8f8;
$accent: #323b4d;
$success: #009c66;
$warning: #b18830;
$error: #a23f3f;
$green: #009b65;
$white: #ffffff;
