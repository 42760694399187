$mobile-width: 440px;
$tablet-width: 1024px;
$desktop-width: 1025px;
$desktop-large-width: 2049px;
$mobile-min: 319px;

@mixin desktop-large {
  @media (min-width: #{$desktop-large-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin small-screens-all {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-landscape {
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

@mixin mobile {
  @media screen and (min-width: #{$mobile-min}) and (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mobile-landscape {
  @media only screen and (min-width: #{$mobile-width}) and (max-width: #{$tablet-width}) and (orientation: landscape) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

@mixin ie10-up {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin edge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin firefox {
  @supports (-moz-appearance: meterbar) and (list-style-type: japanese-formal) {
    @content;
  }
}

@mixin mobile-and-tablet {
  @media screen and (max-width: #{$mobile-width}),
  (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin mobile-and-mobile-landscape {
  @media screen and (max-width: #{$mobile-width}), 
  (min-width: 440px) and (max-width: 812px) and (orientation: landscape){
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin displayFlex {
  display: flex;
}

@mixin flexItemsCenterAligned {
  align-items: center;
}

@mixin flexItemsLeftAligned {
  align-items: left;
}
