.reset-cache {
  &.bottom {
    position: absolute;
    bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
  }
}