#async-loading,
#async-error {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;

  .async-error-text {
    color: white;
    font-size: 1em;
  }

  .c3d-loader {
    margin-bottom: 30px;
    width: 100px;

    .loader-1 {
      fill: #7a797b;
    }

    .loader-1-inner {
      fill: white;
    }

    .loader-2 {
      fill: #99999a;
      -webkit-animation: c3dLoadFirst infinite 2000ms;
      -moz-animation: c3dLoadFirst infinite 2000ms;
      animation: c3dLoadFirst infinite 2000ms;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-duration: 2000ms;
      -moz-animation-duration: 2000ms;
      animation-duration: 2000ms;
      opacity: 0;
    }

    .loader-3 {
      fill: #079b66;
      -webkit-animation: c3dLoadSecond infinite 2000ms;
      -moz-animation: c3dLoadecond infinite 2000ms;
      animation: c3dLoadSecond infinite 2000ms;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-duration: 2000ms;
      -moz-animation-duration: 2000ms;
      animation-duration: 2000ms;
      opacity: 0;
    }
  }
}
// }

@keyframes c3dLoadFirst {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes c3dLoadSecond {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
